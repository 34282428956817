import axios from 'axios';

const userBaseUrl = process.env.REACT_APP_USER_BASE_URL;
const utilityBaseUrl = process.env.REACT_APP_UTILITY_BASE_URL;

export const axiosUserInstance = axios.create({
    baseURL: userBaseUrl
})

export const axiosUtilityInstance = axios.create({
    baseURL: utilityBaseUrl
})