import React, { useEffect, useState } from "react";
import { Accordion, Button, Table, Modal } from "react-bootstrap";
import {
  applyFilters,
  getExamMarks,
  getExamTypes,
  getSubjectsAndPaper,
} from "../../axios/services/userService";
import "./Exams.css";
import { FiFilter } from "react-icons/fi";

const Exams = ({ enrollmentId }) => {
  const [examMarks, setExamMarks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [selectedExamType, setSelectedExamType] = useState("");
  const [selectedSubjects, setSelectedSubjects] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [examTypes, setExamTypes] = useState([]);

  const studentId = localStorage.getItem("studentId");

  useEffect(() => {
    const fetchExamMarks = async () => {
      const { examMarks } = await getExamMarks(enrollmentId);
      setExamMarks(examMarks);
    };
    fetchExamMarks();
  }, [enrollmentId]);

  useEffect(() => {
    const fetchSubjectsAndPaper = async () => {
      const { subjects } = await getSubjectsAndPaper();
      setSubjects(subjects);
    };
    fetchSubjectsAndPaper();
  }, []);

  useEffect(() => {
    const fetchExamTypes = async () => {
      const { examTypes } = await getExamTypes();
      setExamTypes(examTypes);
    };
    fetchExamTypes();
  }, []);

  const isEmptyData = (data) => {
    return (
      data.length === 0 ||
      (data.length === 1 && Object.keys(data[0]).length === 0)
    );
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleResetFilter = () => {
    setFilteredResults([]);
    setSelectedDateRange("");
    setSelectedExamType("");
    setSelectedSubjects("");
    setNoResults(false);
  };

  const filterLoadedData = (resultIds) => {
    const resultIdSet = new Set(resultIds.map((item) => item.name));

    const filtered = examMarks.map((exam) => {
      const date = Object.keys(exam)[0];
      const results = exam[date].results.filter((result) =>
        resultIdSet.has(result.name)
      );

      return results.length > 0 ? { [date]: { results } } : null;
    });
    // Remove null entries and set filtered results
    setFilteredResults(filtered.filter((item) => item !== null));
  };

  const calculateDateRange = (days) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - days);

    return {
      startDate: startDate.toISOString().split("T")[0], // Format as YYYY-MM-DD
      endDate: endDate.toISOString().split("T")[0], // Format as YYYY-MM-DD
    };
  };

  const handleFilterApply = async () => {
    let dateRange = null;
    if (selectedDateRange) {
      dateRange = calculateDateRange(parseInt(selectedDateRange));
    }
    const { data } = await applyFilters(
      studentId,
      dateRange,
      selectedExamType,
      selectedSubjects
    );
    const resultIds = data.result;
    if (resultIds.length > 0) {
      setNoResults(false);
      filterLoadedData(resultIds);
    } else {
      setNoResults(true);
      setFilteredResults([]);
    }
    setShowModal(false);
  };

  return (
    <>
      <div className="exam-mark-section">
        <Button
          variant="secondary"
          className="filter-button"
          onClick={handleShowModal}
        >
          <FiFilter className="filter-icon" />
          Filter Results
        </Button>

        {/* Modal for Filtering */}
        <Modal show={showModal} onHide={handleCloseModal} className="modal">
          <Modal.Header closeButton className="modal-header-center">
            <Modal.Title>All</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Date Range</h5>
            <div>
              <Button
                variant={selectedDateRange === "7" ? "primary" : "outline-dark"}
                onClick={() => setSelectedDateRange("7")}
                className="mb-2 date-range-button"
              >
                Last 7 Days
              </Button>
              <Button
                variant={
                  selectedDateRange === "30" ? "primary" : "outline-dark"
                }
                onClick={() => setSelectedDateRange("30")}
                className="mb-2 date-range-button"
              >
                Last 30 Days
              </Button>
              <Button
                variant={
                  selectedDateRange === "90" ? "primary" : "outline-dark"
                }
                onClick={() => setSelectedDateRange("90")}
                className="mb-2 date-range-button"
              >
                Last 90 Days
              </Button>
            </div>
            <h5 className="mt-4">Exam Type</h5>
            <div>
              {examTypes.map((examType, index) => (
                <Button
                  key={index}
                  variant={
                    selectedExamType === examType.name
                      ? "primary"
                      : "outline-dark"
                  }
                  onClick={() => setSelectedExamType(examType.name)}
                  className="mb-2 exam-type-button"
                >
                  {examType.name}
                </Button>
              ))}
            </div>
            <h5 className="mt-4">Subjects</h5>
            <div>
              {subjects.map((subject, index) => (
                <Button
                  key={index}
                  variant={
                    selectedSubjects === subject.name
                      ? "primary"
                      : "outline-dark"
                  }
                  onClick={() => setSelectedSubjects(subject.name)}
                  className="mb-2 subjects-button"
                >
                  {subject.name}
                </Button>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <Button
              className="modal-reset-button"
              variant="secondary"
              onClick={handleResetFilter}
            >
              Reset
            </Button>
            <Button
              className="modal-apply-filter"
              variant="primary"
              onClick={handleFilterApply}
            >
              Apply Filter
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="exam-mark-table">
          {filteredResults.length > 0 ? (
            <div
              style={{
                marginBottom: "10px",
                color: "gray",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Showing filtered results
            </div>
          ) : (
            noResults && (
              <div
                style={{
                  marginBottom: "10px",
                  color: "gray",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                No data found based on filter input
              </div>
            )
          )}
          <Accordion defaultActiveKey="">
            {isEmptyData(
              filteredResults.length > 0 ? filteredResults : examMarks
            ) ? (
              <span>
                Dear Student, your data import is still in process. Please check
                back later after a few days.
              </span>
            ) : (
              (filteredResults.length > 0 ? filteredResults : examMarks).map(
                (exam, index) => {
                  const date = Object.keys(exam)[0];
                  const results = exam[date].results;
                  return (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>{date}</Accordion.Header>
                      <Accordion.Body style={{ padding: 0 }}>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Subjects</th>
                              <th>Marks Earned</th>
                              <th>Total Marks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {results.map((result, i) => (
                              <tr key={i}>
                                <td>{result?.subject}</td>
                                <td>{result?.student_mark}</td>
                                <td>{result?.exam_total}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                }
              )
            )}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default Exams;
