import React, { useState } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import "./Login.css";
import OtpField from "../../components/OtpField";
import { sendOtp, validateOTP } from "../../axios/services/utilityService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  getCourseList,
  getStudentFromMobile,
} from "../../axios/services/userService";
import { ClipLoader } from "react-spinners";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState();
  const [step, setStep] = useState(1); // 1 for mobile number, 2 for OTP
  const [otpSent, setOtpSent] = useState(false);
  const [errors, setErrors] = useState({ mobileNumber: "", otp: "" });
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const navigate = useNavigate();

  const setStudentId = (studentId) => {
    return localStorage.setItem("studentId", studentId);
  };

  const validateMobile = () => {
    let valid = true;
    const newErrors = {};

    if (!mobileNumber || mobileNumber.length < 10) {
      newErrors.mobileNumber = "Enter a valid mobile number";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const validate_OTP = () => {
    let valid = true;
    const newErrors = {};

    if (!/^\d{4}$/.test(otp)) {
      newErrors.otp = "Enter a valid 4-digit OTP";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (step === 1) {
      if (!validateMobile()) {
        toast.error(
          errors.mobileNumber || "Enter a valid 10-digit mobile number"
        );
        return;
      }

      const response = await sendOtp(mobileNumber);
      if (response.success) {
        toast.success(`An OTP has been sent to ${mobileNumber}`);
        setOtpSent(true);
        setStep(2);
      }
    } else if (step === 2) {
      if (!validate_OTP()) {
        toast.error(errors.otp || "Enter a valid 4-digit OTP");
        return;
      }

      setLoading(true); // Set loading to true

      const response = await validateOTP(mobileNumber, otp);
      if (response.validated.message) {
        const { studentId } = await getStudentFromMobile(mobileNumber);
        setStudentId(studentId);
        const courses = await getCourseList(studentId);
        setCourseList(courses);
        if (courses.length === 1) {
          navigate(`/course-details`, { state: { course: courses[0] } });
        } else {
          navigate(`/course-list`);
        }
      } else {
        setOtpSent(false);
        toast.error("Invalid OTP. Please try again.");
      }

      setLoading(false); // Set loading to false
    }
  };

  return (
    <div className="login-page">
      <MDBCard alignment="center">
        <MDBIcon className="fa-5px p-4" />
        <h4>LOGIN</h4>
        <MDBCardBody>
          <MDBValidation onSubmit={handleSubmit} noValidate className="row g-3">
            {step === 1 && (
              <MDBValidationItem
                className="col-md-12"
                feedback="Please provide your mobile number"
                invalid
              >
                {/* <div className="col-md-12"> */}
                {/* <MDBInput
                    label="Enter your registered mobile number"
                    type="number"
                    name="mobileNumber"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    required
                  /> */}
                <div className="phone-input-wrapper">
                  <PhoneInput
                    // placeholder="Enter your registered mobile number"
                    country={"in"}
                    value={mobileNumber}
                    onChange={(phone) => setMobileNumber(phone)}
                    enableSearch="true"
                    inputProps={{
                      placeholder: "Enter your registered mobile number",
                      inputMode: "tel",
                    }}
                    required
                  />
                </div>
                {/* </div> */}
              </MDBValidationItem>
            )}

            {step === 2 && (
              <OtpField
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                otpSent={otpSent}
              />
            )}

            <MDBBtn
              // style={{ width: "100%" }}
              className="otp-login-button mt-4"
              disabled={loading}
            >
              {loading ? (
                <ClipLoader color="#fff" loading={loading} size={20} />
              ) : step === 1 ? (
                "Send OTP"
              ) : (
                "Proceed"
              )}
            </MDBBtn>
          </MDBValidation>
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};

export default Login;
